import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

*focus{
  outline: none;
}

html {
  opacity: 0;
  transition: 250ms opacity ease;
}

html.wf-active,
html.wf-inactive {
  opacity: 1;
}

body {
  font-family: "Standard-Book", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;


  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #353535;
  margin: 0;

  word-break: break-word;
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Standard-Book", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;

  margin: 0;
}

em {
  font-family: "Standard-BookItalic", "Helvetica Neue", "Lucida Grande", sans-serif;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    border: 0;
    -webkit-apperance: none;

    cursor:pointer;

    padding: 0;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: #aaaaaa;
  text-decoration: none;
}

a:hover{
  color: #353535;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;

  &:empty{
    height: 1em;
  }
}

strong {
  font-weight: normal;
  font-style: normal;

  color: rgba(170, 170, 170, 1);

  font-size: 13px;
}

ol, ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

.grey-text {
  color: rgba(170, 170, 170, 1);

  &:visited{
    color: rgba(170, 170, 170, 1);
  }
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}
`;

export default GlobalStyle;
