import React from "react";
import styled from "styled-components";

export const Page = styled.div`
  padding: 0 20px 20px 20px;
  margin: 66px 0 0 0;

  @media (max-width: 767px) {
    margin: 46px 0 0 0;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 20px;

  @media (max-width: 767px) {
    display: block;
  }
`;

export const ActiveImageContainer = styled.div`
  grid-column: span 3;
`;

export const ProjectsList = styled.div`
  grid-column-start: 5;
  grid-column-end: 9;

  margin: -7px 0 0 0;

  & li {
    width: fit-content;
    padding: 7px 0;
    cursor: pointer;

    & a {
      color: rgba(170, 170, 170, 1);
    }

    @media (max-width: 767px) {
      padding: 15px 0;
    }
  }

  & li.active {
    & a {
      color: #353535;
    }
  }

  @media (max-width: 767px) {
    margin: 0;
  }
`;
