import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const ProjectsList = styled.div`
  margin: 0;

  & li {
    width: 100%;
    color: rgba(170, 170, 170, 1);

    display: block;

    padding: 15px 0;

    & a {
      color: rgba(170, 170, 170, 1);
    }

    & span {
      display: block;
    }
  }
`;

export const PublicationMobile = ({ data }) => {
  const content = data.prismicPublications.data.publications
    .filter(publication => publication.publication.document !== null)
    .map(publication => (
      <li key={`publication_project_${publication.publication.document.id}`}>
        {publication.publication.document.data.link_to_text_page === false ? (
          <a href={publication.publication.document.data.paypal_link.url}>
            <span>{publication.publication.document.data.title.text}</span>
            <span>{publication.publication.document.data.text.text}</span>
          </a>
        ) : (
          <Link to={publication.publication.document.url}>
            <span>{publication.publication.document.data.title.text}</span>
            <span>{publication.publication.document.data.text.text}</span>
          </Link>
        )}
      </li>
    ));

  return (
    <>
      <ProjectsList>
        <ol>{content}</ol>
      </ProjectsList>
    </>
  );
};
