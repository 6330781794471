import React, { useState, useEffect } from "react";
import { Link } from "gatsby";

// Layout
import {
  ActiveImageContainer,
  ProjectsList,
} from "../layout/layout-components";

export const ProjectPhotographyDesktop = ({ data }) => {
  const [activeProject, setActiveProject] = useState(null);

  useEffect(() => {
    const initialContent = data.prismicPhotography.data.projects
      .filter(project => project.project.document !== null)
      .filter((project, index) => index === 0);

    setActiveProject(initialContent[0].project.document);
  }, [data]);

  const content = data.prismicPhotography.data.projects
    .filter(project => project.project.document !== null)
    .map(project => {
      return (
        <li
          key={`photography_project_${project.project.document.id}`}
          className={
            activeProject !== null &&
            activeProject.id === project.project.document.id
              ? `active`
              : ``
          }
          onMouseEnter={() => {
            setActiveProject(project.project.document);
          }}
        >
          <Link to={project.project.document.url}>
            {project.project.document.data.title.text}
          </Link>
        </li>
      );
    });

  return (
    <>
      <ActiveImageContainer>
        {activeProject !== null && activeProject.data.thumbnail.fluid !== null && (
          <Link to={activeProject.url}>
            <img
              srcSet={activeProject.data.thumbnail.fluid.srcSetWebp}
              src={activeProject.data.thumbnail.fluid.srcWebp}
              alt={activeProject.data.thumbnail.alt}
              loading="lazy"
            />
          </Link>
        )}
      </ActiveImageContainer>

      <ProjectsList>
        <ol>
          {content}
          <li
            key={`photography_project_${data.prismicArchive.id}`}
            className={
              activeProject !== null &&
              activeProject.id === data.prismicArchive.id
                ? `active`
                : ``
            }
            onMouseEnter={() => {
              setActiveProject(data.prismicArchive);
            }}
          >
            <Link to={`/archive`}>Archive</Link>
          </li>
        </ol>
      </ProjectsList>
    </>
  );
};

export const ProjectFilmDesktop = ({ data }) => {
  const [activeProject, setActiveProject] = useState(null);

  useEffect(() => {
    const initialContent = data.prismicFilm.data.projects
      .filter(project => project.project.document !== null)
      .filter((project, index) => index === 0);

    setActiveProject(initialContent[0].project.document);
  }, [data]);

  const content = data.prismicFilm.data.projects
    .filter(project => project.project.document !== null)
    .map(project => {
      return (
        <li
          key={`film_project_${project.project.document.id}`}
          className={
            activeProject !== null &&
            activeProject.id === project.project.document.id
              ? `active`
              : ``
          }
          onMouseEnter={() => {
            setActiveProject(project.project.document);
          }}
        >
          <Link to={project.project.document.url}>
            {project.project.document.data.title.text}
          </Link>
        </li>
      );
    });

  return (
    <>
      <ActiveImageContainer>
        {activeProject !== null && activeProject.data.thumbnail.fluid !== null && (
          <Link to={activeProject.url}>
            <img
              srcSet={activeProject.data.thumbnail.fluid.srcSetWebp}
              src={activeProject.data.thumbnail.fluid.srcWebp}
              alt={activeProject.data.thumbnail.alt}
              loading="lazy"
            />
          </Link>
        )}
      </ActiveImageContainer>

      <ProjectsList>
        <ol>{content}</ol>
      </ProjectsList>
    </>
  );
};

export const ProjectArchiveDesktop = ({ data }) => {
  const [activeProject, setActiveProject] = useState(null);

  useEffect(() => {
    const initialContent = data.prismicArchive.data.projects
      .filter(project => project.project.document !== null)
      .filter((project, index) => index === 0);

    setActiveProject(initialContent[0].project.document);
  }, [data]);

  const content = data.prismicArchive.data.projects
    .filter(project => project.project.document !== null)
    .map(project => {
      return (
        <li
          key={`archive_project_${project.project.document.id}`}
          className={
            activeProject !== null &&
            activeProject.id === project.project.document.id
              ? `active`
              : ``
          }
          onMouseEnter={() => {
            setActiveProject(project.project.document);
          }}
        >
          <Link to={project.project.document.url}>
            {project.project.document.data.title.text}
          </Link>
        </li>
      );
    });

  return (
    <>
      <ActiveImageContainer>
        {activeProject !== null && activeProject.data.thumbnail.fluid !== null && (
          <Link to={activeProject.url}>
            <img
              srcSet={activeProject.data.thumbnail.fluid.srcSetWebp}
              src={activeProject.data.thumbnail.fluid.srcWebp}
              alt={activeProject.data.thumbnail.alt}
              loading="lazy"
            />
          </Link>
        )}
      </ActiveImageContainer>

      <ProjectsList>
        <ol>{content}</ol>
      </ProjectsList>
    </>
  );
};
