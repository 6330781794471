import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Helmet from "react-helmet";

// Layout
import { Page } from "../components/layout/layout-components";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const ProjectsList = styled.div`
  margin: 0;

  & .container {
    margin: 0 0 40px 0;

    & h2 {
      margin: 0 0 10px 0;
    }
  }

  & li {
    width: 100%;
    color: rgba(170, 170, 170, 1);

    display: block;

    padding: 7px 0;

    & a {
      color: rgba(170, 170, 170, 1);

      &:hover {
        color: #353535;
      }
    }

    & span {
      display: block;
    }

    @media (max-width: 767px) {
      padding: 15px 0;
    }
  }
`;
const Writing = ({ data }) => {
  const essays = data.prismicWritingOverview.data.essays
    .filter(article => article.article.document !== null)
    .map((article, index) => (
      <li key={`essay_article_${article.article.document.id}_${index}`}>
        <a href={article.article.document.url}>
          <span>{article.article.document.data.title.text}</span>
        </a>
      </li>
    ));

  const interviews = data.prismicWritingOverview.data.interviews
    .filter(article => article.article.document !== null)
    .map((article, index) => (
      <li key={`interview_article_${article.article.document.id}_${index}`}>
        <a href={article.article.document.url}>
          <span>{article.article.document.data.title.text}</span>
        </a>
      </li>
    ));

  const reviews = data.prismicWritingOverview.data.reviews
    .filter(article => article.article.document !== null)
    .map((article, index) => (
      <li key={`reviews_article_${article.article.document.id}_${index}`}>
        <a href={article.article.document.url}>
          <span>{article.article.document.data.title.text}</span>
        </a>
      </li>
    ));

  return (
    <>
      <Helmet title={`Writing | Mitra Tabrizian`} />
      <Page>
        <ProjectsList>
          <div className="container">
            <h2>Essays</h2>
            <ol>{essays}</ol>
          </div>

          <div className="container">
            <h2>Interviews</h2>
            <ol>{interviews}</ol>
          </div>

          <div className="container">
            <h2>Reviews</h2>
            <ol>{reviews}</ol>
          </div>
        </ProjectsList>
      </Page>
    </>
  );
};

export default withPrismicPreview(Writing);

export const query = graphql`
  {
    prismicWritingOverview {
      url
      _previewable
      data {
        essays {
          article {
            document {
              ... on PrismicWriting {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        interviews {
          article {
            document {
              ... on PrismicWriting {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
        reviews {
          article {
            document {
              ... on PrismicWriting {
                id
                url
                data {
                  title {
                    text
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
