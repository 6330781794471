import React from "react";
import styled from "styled-components";

const Grid = styled.div`
  & > div {
    max-width: 650px;
  }

  & .footnotes {
    color: rgba(170, 170, 170, 1);
  }
`;

export const About = ({ text }) => (
  <div className="content-container">
    <Grid>{text}</Grid>
  </div>
);
