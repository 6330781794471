import React from "react";
import { Link } from "gatsby";

// Layout
import { ProjectsList } from "../layout/layout-components";

export const ProjectPhotographyMobile = ({ data }) => {
  const content = data.prismicPhotography.data.projects
    .filter(project => project.project.document !== null)
    .map(project => {
      return (
        <li key={`photography_project_${project.project.document.id}`}>
          <Link to={project.project.document.url}>
            {project.project.document.data.title.text}
          </Link>
        </li>
      );
    });

  return (
    <ProjectsList>
      <ol>
        {content}
        <li key={`photography_project_${data.prismicArchive.id}`}>
          <Link to={`/archive`}>Archive</Link>
        </li>
      </ol>
    </ProjectsList>
  );
};

export const ProjectFilmMobile = ({ data }) => {
  const content = data.prismicFilm.data.projects
    .filter(project => project.project.document !== null)
    .map(project => {
      return (
        <li key={`film_project_${project.project.document.id}`}>
          <Link to={project.project.document.url}>
            {project.project.document.data.title.text}
          </Link>
        </li>
      );
    });

  return (
    <ProjectsList>
      <ol>{content}</ol>
    </ProjectsList>
  );
};

export const ProjectArchiveMobile = ({ data }) => {
  const content = data.prismicArchive.data.projects
    .filter(project => project.project.document !== null)
    .map(project => {
      return (
        <li key={`archive_project_${project.project.document.id}`}>
          <Link to={project.project.document.url}>
            {project.project.document.data.title.text}
          </Link>
        </li>
      );
    });

  return (
    <ProjectsList>
      <ol>{content}</ol>
    </ProjectsList>
  );
};
