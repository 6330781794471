import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import Helmet from "react-helmet";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Page = styled.div`
  padding: 0 20px;
`;

const TopCaption = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 18px 0 22px 0;

  & a {
    color: rgba(170, 170, 170, 1);
    margin: 0 0 0 auto;

    &:hover {
      color: #353535;
    }

    @media (max-width: 767px) {
      margin: 0;
    }
  }

  & .title-container {
    & p {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    padding: 10px 0 22px 0;
  }
`;

const Caption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 100;

  overflow: scroll;

  height: ${props => props.height};
  min-height: ${props => props.minHeight};

  transform: translateY(${props => props.translateY});
  transition: 500ms transform ease;

  background: #fff;

  & .top-line-information {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    padding: 26px 20px 0 20px;

    & button {
      &:first-of-type {
        margin: 0 20px 0 0;
      }

      &:hover {
        color: rgba(170, 170, 170, 1);
      }
    }
  }

  & .content-container {
    padding: 12px 20px 20px 20px;
  }

  @media (max-width: 767px) {
    & .project-title {
      display: none;
    }

    & .buttons {
      width: 100%;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }
`;

const Counter = styled.div`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

const InterviewText = styled.div`
  & .name {
    margin-bottom: 0;
    padding: 0 0 0 25px;
  }

  & .body-text {
    & p:first-of-type {
      margin-top: 0;
    }
  }
`;

const InterviewTextBottom = styled.div`
  & .name {
    margin-top: 0;
    padding: 0 0 0 25px;
  }

  & .body-text {
    & p:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const QuoteText = styled.div`
  color: #353535;
  padding: 0 0 0 25px;

  & p:last-of-type {
    margin: 0;
  }
`;

const FootnoteText = styled.div`
  margin: 1em 0;
  color: #aaaaaa;

  & p {
    margin: 0;

    font-size: 11px;
    line-height: 18px;
  }
`;

const ContentContainer = styled.div`
  max-width: 650px;
`;

const PublicationArticle = ({ data }) => {
  const content = data.prismicPublication.data.body.map((content, index) => {
    if (content.slice_type === "text") {
      return (
        <div key={`body_text_${index}_${content.id}`}>
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </div>
      );
    }

    if (content.slice_type === "interview_text") {
      return (
        <InterviewText key={`interview_text_${index}_${content.id}`}>
          <p className="name">{content.primary.name}</p>
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </InterviewText>
      );
    }

    if (content.slice_type === "interview_text_bottom") {
      return (
        <InterviewTextBottom
          key={`interview_text_bottom_${index}_${content.id}`}
        >
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
          <p className="name">{content.primary.name}</p>
        </InterviewTextBottom>
      );
    }

    if (content.slice_type === "quote_text") {
      return (
        <QuoteText key={`quote_text_${index}_${content.id}`}>
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
          <p className="name">{content.primary.name}</p>
        </QuoteText>
      );
    }

    if (content.slice_type === "footnotes") {
      return (
        <FootnoteText key={`footnotes_${index}_${content.id}`}>
          <div
            className="body-text"
            dangerouslySetInnerHTML={{
              __html: content.primary.text.html,
            }}
          />
        </FootnoteText>
      );
    }
  });

  return (
    <>
      <Helmet
        title={`${data.prismicPublication.data.title.text} | Mitra Tabrizian`}
        meta={[
          {
            name: "og:image",
            content: `${data.prismicPublication.data.thumbnail.url}`,
          },
          {
            name: "og:image:secure_url",
            content: `${data.prismicPublication.data.thumbnail.url}`,
          },
          {
            name: "og:image:width",
            content: `1200`,
          },
          {
            name: "og:image:height",
            content: `630`,
          },
          {
            name: "og:locale",
            content: `en`,
          },
          {
            name: "twitter:title",
            content: `${data.prismicPublication.data.title.text} | Mitra Tabrizian`,
          },
          {
            name: "twitter:card",
            content: `summary_large_image`,
          },
          {
            name: "twitter:image",
            content: `${data.prismicPublication.data.thumbnail.url}`,
          },
        ]}
      />

      <Div100vh>
        <Page>
          <TopCaption>
            <div className="title-container">
              <div
                className="body-text"
                dangerouslySetInnerHTML={{
                  __html: data.prismicPublication.data.title.html,
                }}
              />
            </div>
            <Link to={"/publication"}>Back</Link>
          </TopCaption>

          <ContentContainer>{content}</ContentContainer>
        </Page>
      </Div100vh>
    </>
  );
};

export default withPrismicPreview(PublicationArticle);

export const query = graphql`
  query PublicationArticle($uid: String!) {
    prismicPublication(uid: { eq: $uid }) {
      url
      _previewable
      data {
        title {
          html
          text
        }
        thumbnail {
          url
        }
        body {
          ... on PrismicPublicationDataBodyText {
            id
            slice_type
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicPublicationDataBodyInterviewText {
            id
            slice_type
            primary {
              name
              text {
                html
              }
            }
          }
          ... on PrismicPublicationDataBodyInterviewTextBottom {
            id
            slice_type
            primary {
              name
              text {
                html
              }
            }
          }
          ... on PrismicPublicationDataBodyQuoteText {
            id
            primary {
              text {
                html
              }
              name
            }
            slice_type
          }
          ... on PrismicPublicationDataBodyFootnotes {
            id
            primary {
              text {
                html
              }
            }
            slice_type
          }
        }
      }
    }
  }
`;
