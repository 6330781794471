import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Helmet from "react-helmet";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const Page = styled.div`
  padding: 0 20px;
  margin: 66px 0 0 0;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-gap: 20px;

  & .grid-span-6 {
    grid-column: span 6;

    & .right-grid-column {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      & .column {
        grid-column-start: 3;
        grid-column-end: 5;
      }
    }

    & .column {
      max-width: 650px;
    }

    @media (max-width: 1000px) {
      grid-column: span 5;
    }
  }

  & .grid-span-2 {
    grid-column: span 2;

    @media (max-width: 1000px) {
      grid-column: span 3;
    }
  }

  & .contact-text {
    color: rgba(170, 170, 170, 1);

    & a {
      color: rgba(170, 170, 170, 1);

      &:hover {
        color: #353535;
      }
    }

    & p:nth-of-type(2) {
      color: #353535;
    }

    @media (max-width: 767px) {
      margin: 100px 0 0 0;
    }
  }

  & .credits-text {
    margin: 2em 0 1em 0;
  }

  @media (max-width: 767px) {
    display: block;
  }
`;

const About = ({ data }) => (
  <>
    <Helmet title={`About | Mitra Tabrizian`} />
    <Page>
      <Grid>
        <div className="grid-span-6">
          <div
            className="column"
            dangerouslySetInnerHTML={{
              __html: data.prismicAbout.data.text.html,
            }}
          />

          <div>
            <a
              href={data.prismicAbout.data.cv.url}
              download
              className="grey-text"
              target="_blank"
            >
              Download CV
            </a>
          </div>
        </div>
        <div className="grid-span-2">
          <div className="right-grid-column">
            <div
              className="column contact-text"
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.contact_text.html,
              }}
            />

            <div
              className="column credits-text"
              dangerouslySetInnerHTML={{
                __html: data.prismicAbout.data.credits.html,
              }}
            />
          </div>
        </div>
      </Grid>
    </Page>
  </>
);

export default withPrismicPreview(About);

export const query = graphql`
  {
    prismicAbout {
      url
      _previewable
      data {
        text {
          html
        }
        contact_text {
          html
        }
        credits {
          html
        }
        cv {
          url
        }
      }
    }
  }
`;
