import React from "react";
import { graphql } from "gatsby";
import { createBreakpoint } from "react-use";

// Layout
import { Page, Grid } from "../components/layout/layout-components";

// Components
import { ProjectFilmDesktop } from "../components/projects/project-desktop";
import { ProjectFilmMobile } from "../components/projects/project-mobile";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const useBreakpoint = createBreakpoint({ L: 768, S: 767 });

const Film = ({ data }) => {
  const breakpoint = useBreakpoint();

  return (
    <Page>
      <Grid>
        {breakpoint === "L" ? (
          <ProjectFilmDesktop data={data} />
        ) : (
          <ProjectFilmMobile data={data} />
        )}
      </Grid>
    </Page>
  );
};

export default withPrismicPreview(Film);

export const query = graphql`
  {
    prismicFilm {
      url
      _previewable
      data {
        projects {
          project {
            document {
              ... on PrismicFilmProject {
                id
                url
                data {
                  title {
                    text
                  }
                  thumbnail {
                    alt
                    fluid {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
