exports.linkResolver = doc => {
  switch (doc.type) {
    case "homepage": {
      return `/`;
    }

    default: {
      return `/${doc.uid}/`;
    }
  }
};
