import React from "react";
import styled from "styled-components";

// Utils
import { ImageOrientation } from "../utils/image-orientation";

const PhotographyGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & .thumbnail {
    height: 200px;
    width: auto;

    margin: 0 1.25em 1.25em 0;

    cursor: pointer;

    & img {
      width: auto;
      height: 100%;
    }
  }
`;

const FilmGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  & .thumbnail {
    height: 320px;
    width: auto;

    margin: 0 40px 40px 0;

    & img {
      width: auto;
      height: 100%;
    }
  }
`;

const TrailerLink = styled.div``;

export const Thumbnails = ({
  images,
  setCurrentSlide,
  setIsThumbnailsVisible,
}) => {
  const galleryContent = images.map((content, index) => (
    <div
      className="thumbnail"
      key={`homepage_images_${index}`}
      onClick={() => {
        setCurrentSlide(index);
        setIsThumbnailsVisible(false);
      }}
    >
      {content.image.fluid !== null && (
        <img
          className={ImageOrientation(content.image)}
          srcSet={content.image.fluid.srcSetWebp}
          src={content.image.fluid.srcWebp}
          alt={content.image.alt}
          loading={index <= 2 || index === images.length - 1 ? `eager` : `lazy`}
        />
      )}
    </div>
  ));

  return (
    <div className="content-container">
      <PhotographyGrid>{galleryContent}</PhotographyGrid>
    </div>
  );
};

export const FilmThumbnails = ({ images, trailer }) => {
  const galleryContent = images.map((content, index) => (
    <div className="thumbnail" key={`homepage_images_${index}`}>
      {content.image.fluid !== null && (
        <img
          className={ImageOrientation(content.image)}
          srcSet={content.image.fluid.srcSetWebp}
          src={content.image.fluid.srcWebp}
          alt={content.image.alt}
          loading={index <= 2 || index === images.length - 1 ? `eager` : `lazy`}
        />
      )}
    </div>
  ));

  return (
    <div className="content-container">
      <FilmGrid>{galleryContent}</FilmGrid>

      {trailer.text !== "" && (
        <TrailerLink
          dangerouslySetInnerHTML={{
            __html: trailer.html,
          }}
        />
      )}
    </div>
  );
};
