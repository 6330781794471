import React from "react";
import { Location } from "@reach/router";
import { Normalize } from "styled-normalize";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";

const Layout = ({ children }) => (
  <Location>
    {({ location }) => {
      return (
        <>
          <Normalize />
          <GlobalStyles />
          <DefaultSEO location={location} />
          <App location={location.pathname} children={children} />
        </>
      );
    }}
  </Location>
);

export default Layout;
