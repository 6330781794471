import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import "../styles/gallery.css";
import { use100vh } from "react-div-100vh";
import { useMouse } from "react-use";
import { isMobile } from "react-device-detect";

const GalleryContainer = styled.div`
  position: relative;

  width: 100%;
  height: calc(100% - 61px - 61px);

  & .carousel.carousel-slider,
  & .slider-wrapper,
  & .image-gallery,
  & .slider,
  & .slide,
  & img {
    height: 100%;
  }

  & .carousel.carousel-slider .control-arrow {
    padding: 0;
    width: 50%;
  }

  & .counter-prev {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 100;

    cursor: none;

    width: 50%;

    overflow: hidden;

    & span.counter {
      position: absolute;

      width: 100px;

      & .text {
        color: #aaaaaa;
      }
    }
  }

  & .counter-next {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;

    cursor: none;

    width: 50%;

    overflow: hidden;

    & span.counter {
      position: absolute;

      width: 100px;

      & .text {
        color: #aaaaaa;
      }
    }
  }
`;

const ImageContainer = styled.div`
  position: relative;

  width: 100%;
  height: 100%;

  overflow: hidden;

  padding: 0 20px;

  & a {
    display: block;

    width: 100%;
    height: 100%;

    position: relative;
  }

  & img {
    max-width: 100%;

    object-fit: contain;
  }
`;

export const Gallery = ({
  images,
  currentSlide,
  setCurrentSlide,
  totalSlides,
  setTotalSlides,
}) => {
  const height = use100vh();

  const refPrev = useRef(null);
  const prevMouseData = useMouse(refPrev);

  const refNext = useRef(null);
  const nextMouseData = useMouse(refNext);

  useEffect(() => {
    setTotalSlides(images.length);
  }, [setTotalSlides]);

  const updateCurrentSlide = index => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  const galleryContent = images.map((content, index) => (
    <ImageContainer key={`homepage_images_${index}`}>
      {content.image.fluid !== null && (
        <img
          srcSet={content.image.fluid.srcSetWebp}
          src={content.image.fluid.srcWebp}
          alt={content.image.alt}
          loading={index <= 2 || index === images.length - 1 ? `eager` : `lazy`}
        />
      )}
    </ImageContainer>
  ));

  return (
    <GalleryContainer height={height}>
      {galleryContent.length > 1 && (
        <Carousel
          renderArrowPrev={onClickHandler => (
            <button
              ref={refPrev}
              className="counter-prev"
              type="button"
              onClick={onClickHandler}
            >
              {!isMobile && (
                <span
                  className="counter"
                  style={{
                    top: `${prevMouseData.elY - 75}px`,
                    left: `${prevMouseData.elX - 50}px`,
                  }}
                >
                  {currentSlide + 1}/{totalSlides}{" "}
                  <span className="text">prev</span>
                </span>
              )}
            </button>
          )}
          renderArrowNext={onClickHandler => (
            <button
              ref={refNext}
              className="counter-next"
              type="button"
              onClick={onClickHandler}
            >
              {!isMobile && (
                <span
                  className="counter"
                  style={{
                    top: `${nextMouseData.elY - 75}px`,
                    left: `${nextMouseData.elX - 50}px`,
                  }}
                >
                  {currentSlide + 1}/{totalSlides}{" "}
                  <span className="text">next</span>
                </span>
              )}
            </button>
          )}
          showThumbs={false}
          autoPlay={false}
          showIndicators={false}
          showStatus={false}
          infiniteLoop={true}
          useKeyboardArrows={true}
          className="image-gallery"
          selectedItem={currentSlide}
          onChange={index => updateCurrentSlide(index)}
          stopOnHover={false}
          interval={7000}
          transitionTime={750}
        >
          {galleryContent}
        </Carousel>
      )}

      {galleryContent.length === 1 && <>{galleryContent}</>}
    </GalleryContainer>
  );
};
