import React from "react";
import { graphql } from "gatsby";
import { createBreakpoint } from "react-use";
import Helmet from "react-helmet";

// Layout
import { Page, Grid } from "../components/layout/layout-components";

// Components
import { PublicationDesktop } from "../components/publication/publication-desktop";
import { PublicationMobile } from "../components/publication/publication-mobile";

// Previews
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const useBreakpoint = createBreakpoint({ L: 768, S: 767 });

const Publication = ({ data }) => {
  const breakpoint = useBreakpoint();

  return (
    <>
      <Helmet title={`Publication | Mitra Tabrizian`} />
      <Page>
        <Grid>
          {breakpoint === "L" ? (
            <PublicationDesktop data={data} />
          ) : (
            <PublicationMobile data={data} />
          )}
        </Grid>
      </Page>
    </>
  );
};

export default withPrismicPreview(Publication);

export const query = graphql`
  {
    prismicPublications {
      url
      _previewable
      data {
        publications {
          publication {
            document {
              ... on PrismicPublication {
                id
                url
                data {
                  title {
                    text
                  }
                  text {
                    text
                  }
                  link_to_text_page
                  paypal_link {
                    url
                  }
                  thumbnail {
                    alt
                    fluid {
                      srcWebp
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
