import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Layout
import { ActiveImageContainer } from "../layout/layout-components";

const ProjectsList = styled.div`
  grid-column-start: 5;
  grid-column-end: 9;

  margin: -7px 0 0 0;

  & li {
    width: 100%;
    color: rgba(170, 170, 170, 1);

    padding: 7px 0;

    & a {
      color: rgba(170, 170, 170, 1);
    }

    & .left-column {
      grid-column: span 3;
    }

    & .right-column {
      grid-column: span 2;
    }
  }

  & li.active {
    color: #353535;

    & a {
      color: #353535;
    }
  }
`;

export const PublicationDesktop = ({ data }) => {
  const [activeProject, setActiveProject] = useState(null);

  useEffect(() => {
    const initialContent = data.prismicPublications.data.publications
      .filter(publication => publication.publication.document !== null)
      .filter((publication, index) => index === 0);

    setActiveProject(initialContent[0].publication.document);
  }, [data]);

  const content = data.prismicPublications.data.publications
    .filter(publication => publication.publication.document !== null)
    .map(publication => (
      <li
        key={`publication_project_${publication.publication.document.id}`}
        className={
          activeProject !== null &&
          activeProject.id === publication.publication.document.id
            ? `grid active`
            : `grid `
        }
        onMouseEnter={() => {
          setActiveProject(publication.publication.document);
        }}
      >
        <span className="left-column">
          {publication.publication.document.data.link_to_text_page === false ? (
            <a href={publication.publication.document.data.paypal_link.url}>
              {publication.publication.document.data.title.text}
            </a>
          ) : (
            <Link to={publication.publication.document.url}>
              {publication.publication.document.data.title.text}
            </Link>
          )}
        </span>
        <span className="right-column">
          {publication.publication.document.data.text.text}
        </span>
      </li>
    ));

  return (
    <>
      <ActiveImageContainer>
        {activeProject !== null &&
          activeProject.data.thumbnail.fluid !== null && (
            <img
              srcSet={activeProject.data.thumbnail.fluid.srcSetWebp}
              src={activeProject.data.thumbnail.fluid.srcWebp}
              alt={activeProject.data.thumbnail.alt}
              loading="lazy"
            />
          )}
      </ActiveImageContainer>
      <ProjectsList>
        <ol>{content}</ol>
      </ProjectsList>
    </>
  );
};
