import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import Media from "react-media";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  padding: 18px 20px 30px 20px;

  background-color: #fff;
  z-index: 200;

  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0px;

  @media (max-width: 767px) {
    padding: 10px 20px 20px 20px;
  }
`;

export const Header = ({ location }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          menuLinks {
            name
            link
          }
        }
      }
    }
  `);

  const [windowWidth, setWindowWidth] = useState(768);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);
  return (
    <HeaderWrapper>
      <Media
        queries={{ medium: "(max-width: 768px)" }}
        defaultMatches={{ medium: windowWidth === 768 }}
        render={() => (
          <MobileMenu
            links={data.site.siteMetadata.menuLinks}
            location={location.pathname}
          />
        )}
      />

      <Media
        queries={{ medium: "(min-width: 769px)" }}
        defaultMatches={{ medium: windowWidth === 769 }}
        render={() => (
          <DesktopMenu
            links={data.site.siteMetadata.menuLinks}
            location={location.pathname}
          />
        )}
      />
    </HeaderWrapper>
  );
};
