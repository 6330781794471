import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import { Header } from "./header";

const Main = styled.main`
  flex: 1;
`;

export const App = ({ location, data, children }) => {
  const showHeader =
    location === `/` ||
    location === `/photography` ||
    location === `/photography/` ||
    location === `/archive` ||
    location === `/archive/` ||
    location === `/film` ||
    location === `/film/` ||
    location === `/publication` ||
    location === `/publication/` ||
    location === `/writing` ||
    location === `/writing/` ||
    location === `/about` ||
    location === `/about/`
      ? true
      : false;

  useEffect(() => {
    // console.log(
    //   "%c Kieran Startup",
    //   "padding: 20px 20px 30px 20px; font-family:Helvetica Neue; font-size: 50px;color: red; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)"
    // );

    console.log(
      "%cDevelopment: Kieran Startup",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
    console.log(
      "%cwww.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  return (
    <>
      {showHeader && <Header location={location} />}
      <Main>{children}</Main>
    </>
  );
};
