import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;

  & button {
    padding: 0;
  }

  & a.mitra {
    color: #353535;
  }

  & ol {
    display: block;

    width: 100%;
    min-height: calc(100vh - 20px - 20px - 20px);

    margin: 0;
    padding: 0;

    & li {
      margin: 0;
      padding: 30px 0 0 0;

      & a {
        color: rgba(170, 170, 170, 1);
      }

      & a.current {
        color: #353535;
      }
    }
  }
`;

export const MobileMenu = ({ links }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const pageLinks = links.map((link, index) => (
    <li
      key={`header_link_desktop_${index}`}
      onClick={() => setIsMenuOpen(false)}
    >
      <Link to={link.link} activeClassName="current">
        {link.name}
      </Link>
    </li>
  ));

  return (
    <Nav>
      <li onClick={() => setIsMenuOpen(false)}>
        <Link to={`/`}>mitra tabrizian</Link>
      </li>
      <button onClick={() => setIsMenuOpen(!isMenuOpen)}>
        {isMenuOpen ? `Close` : `Menu`}
      </button>
      {isMenuOpen && <ol>{pageLinks}</ol>}
    </Nav>
  );
};
