import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & a.mitra {
    color: #353535;
  }

  & ol {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;

    & li {
      margin: 0 0 0 20px;

      & a {
        color: rgba(170, 170, 170, 1);

        &:hover {
          color: #353535;
        }
      }

      & a.current {
        color: #353535;
      }
    }
  }
`;

export const DesktopMenu = ({ links }) => {
  const pageLinks = links.map((link, index) => (
    <li key={`header_link_desktop_${index}`}>
      <Link to={link.link} activeClassName="current">
        {link.name}
      </Link>
    </li>
  ));
  return (
    <Nav>
      <li>
        <Link to={`/`} className="mitra">
          mitra tabrizian
        </Link>
      </li>
      <ol>{pageLinks}</ol>
    </Nav>
  );
};
