import * as React from "react";
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews";

// Prismic
import { linkResolver } from "./src/components/link-resolver/linkResolver";

// Pages
import About from "./src/pages/about";
import Archive from "./src/pages/archive";
import Film from "./src/pages/film";
import Index from "./src/pages/index";
import Photography from "./src/pages/photography";
import Publication from "./src/pages/publication";
import Writing from "./src/pages/writing";

// Templates
import FilmProject from "./src/templates/film";
import PhotographyProject from "./src/templates/photography";
import PublicationArticle from "./src/templates/publication";
import WritingArticle from "./src/templates/writing";

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          about: About,
          archive: Archive,
          film_project: FilmProject,
          film: Film,
          homepage: Index,
          photography_project: PhotographyProject,
          photography: Photography,
          publication: PublicationArticle,
          publications: Publication,
          writing_overview: Writing,
          writing: WritingArticle,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
);
